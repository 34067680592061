import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useState } from 'react';
import './app.css';
import 'bootstrap/dist/css/bootstrap.css'
import { Modal2 } from './modal'

import {
  Routes,
  Route,
  Link,
  LinkProps,
  useMatch,
  useResolvedPath,
  useLocation
} from 'react-router-dom';
import { LoggedInUser, PublicEntity } from './types';
import api from './api';

import { LoginRoute, RegisterRoute } from './account'
import { useNavigate } from 'react-router-dom';
import { HomeRoute, LandingRoute } from './home';
import { BillsRoute } from './bills';

const CustomLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const className = (props.className || '') + match ? ' active' : ''
  return (
    <div>
      <Link
        className={className}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}

const Header: FunctionComponent<{
  user: LoggedInUser,
  currentEntity: string,
  refreshUser: () => void,
  onLogout: () => void
}> = ({ user, currentEntity, refreshUser, onLogout }) => {
  const [showNav, setShowNav] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [nextEntityName, setNextEntityName] = useState('');
  const [showEntityModal, setShowEntityModal] = useState(false);
  const dropdownRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && profileDropdown && !dropdownRef.current.contains(event.target as Node)) {
        setProfileDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileDropdown]);



  return <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>

    <Modal2
      isOpen={showEntityModal}
      onClose={() => setShowEntityModal(!showEntityModal)}
      title={'Create profile'}
      submitText={'Create'}
      onSubmit={() => {
        api.createEntity(nextEntityName)
          .then(() => {
            refreshUser();
            setShowEntityModal(false);
          })
      }}>
      <input type='text' className='form-control' placeholder='Name' value={nextEntityName} onChange={e => setNextEntityName(e.currentTarget.value)} />
    </Modal2>
    <div className='container'>
      <Link className='navbar-brand d-flex align-items-center' to='/' >
        <img src='logo512.png' height={40} width={40} className='me-1' />
        PlannedMoney
      </Link>
      <button className='navbar-toggler' type='button' onClick={() => setShowNav(!showNav)}>
        <span className='navbar-toggler-icon'></span>
      </button>
      <div className={'navbar-collapse ' + (showNav ? '' : 'collapse')}>
        <ul className='navbar-nav ms-auto'>
          {user ? <>
            <li className='nav-item'>
              <CustomLink className='nav-link' to='/'>Home</CustomLink>
            </li>
            <li className='nav-item'>
              <CustomLink className='nav-link' to='/bills'>Bills</CustomLink>
            </li>
            <li className='nav-item dropdown' ref={dropdownRef}>
              <a className='nav-link dropdown-toggle cursor-pointer' onClick={() => setProfileDropdown(!profileDropdown)}>
                {user.entities.find(({ id }) => id === currentEntity)?.name}
              </a>
              <ul className={'dropdown-menu ' + (profileDropdown ? 'show' : '')}>
                {user.entities.map(entity => <li key={entity.id}>
                  <a className='dropdown-item cursor-pointer' onClick={() => {
                    api.setLastEntity(entity.id)
                      .then(() => {
                        refreshUser();
                        setProfileDropdown(false);
                      })
                  }}>
                    {entity.name} {user.lastEntity === entity.id ? '✔' : ''}
                  </a>
                </li>)}

                <li><a className='dropdown-item cursor-pointer' onClick={() => {
                  setShowEntityModal(true)
                  setProfileDropdown(false);
                }}>Add Profile</a></li>
                <li><hr className='dropdown-divider' /></li>
                <li><a className='dropdown-item cursor-pointer' onClick={() => {
                  onLogout();
                  setProfileDropdown(false);
                }}>Logout</a></li>
              </ul>
            </li>
          </>
            : <>
              <li className='nav-item'>
                <CustomLink className='nav-link' to='/login'>Login</CustomLink>
              </li>
              <li className='nav-item'>
                <CustomLink className='nav-link' to='/register'>Register</CustomLink>
              </li>
            </>}
        </ul>
      </div>
    </div>
  </nav>
}
const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(undefined as any as LoggedInUser);
  const [currentEntityId, setCurrentEntityId] = useState('');

  const navigate = useNavigate();

  const refreshUser = (showLoading = true) => {
    if (showLoading)
      setLoading(true)
    return api.me()
      .then(user => {
        setUser(user);
        console.log(user);
        setCurrentEntityId(user.entities.find(entity => entity.id === user.lastEntity) ? user.lastEntity : user.entities[0].id);
      })
      .catch(err => console.error(err))
      .then(() => setLoading(false))
  }
  useEffect(() => {
    refreshUser();
  }, [])

  const logout = () => {
    api.logout()
      .then(() => {
        setUser(undefined as any as LoggedInUser);
        navigate('/login', { state: { loggedout: true } })
      })
      .catch(err => {
        console.error(err);
      })
  }

  return <div>
    <Header user={user} currentEntity={currentEntityId} refreshUser={() => refreshUser()} onLogout={logout} />
    <div className='fluid-container'>
      <div className={loading ? '' : 'd-none'}>
        <h1>Loading</h1>
      </div>
      <div className={loading ? 'd-none' : ''}>
        <Routes>
          <Route path='/' element={user ? <HomeRoute entityId={currentEntityId} /> : <LandingRoute />} />
          <Route path='/bills' element={user ? <BillsRoute
            entity={user.entities.find(({ id }) => id === currentEntityId) as PublicEntity}
            refreshEntity={() => {
              refreshUser(false);
            }} /> : <span>Loading</span>} />
          <Route path='/login' element={<LoginRoute onLogin={() => {
            refreshUser()
              .then(() => navigate('/'))
          }} />} />
          <Route path='/register' element={<RegisterRoute onRegistered={() => navigate('/login', { state: { registered: true } })} />} />
        </Routes>
      </div>
    </div>
  </div>
}

export default App;
