import React, { FunctionComponent, useEffect, useState } from 'react';
import api from './api';
import { useLocation } from 'react-router-dom';

export const LoginRoute: FunctionComponent<{ onLogin: () => void }> = ({ onLogin }) => {
    const location = useLocation();

    return <div className='row m-0'>
        <div className='offset-md-3 col-md-6 mt-3'>
            <Login onLogin={onLogin} justRegistered={location.state?.registered} loggedOut={location.state?.loggedout} />
        </div>
    </div>
}
const Login: FunctionComponent<{ onLogin: () => void, justRegistered?: boolean, loggedOut?: boolean }> = ({ onLogin, justRegistered, loggedOut }) => {
    const [submittable, setSubmittable] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alertIsError, setAlertIsError] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);

    useEffect(() => {
        if (justRegistered) {
            setAlertIsError(false);
            setAlertText('Successfully registered. Please log in.')
        } else if (loggedOut) {
            setAlertIsError(false);
            setAlertText('Successfully logged out.')
        }
    }, [])
    useEffect(() => {
        setSubmittable(checkSubmittable());
    }, [email, password])

    const checkSubmittable = () => {
        if (email && password) {
            return true;
        } else {
            return false;
        }
    }
    const login = () => {
        if (!checkSubmittable())
            return;
        setLoggingIn(true)
        setAlertText('');

        api.login(email, password)
            .then(() => onLogin())
            .catch(err => {
                setAlertText(err);
                setAlertIsError(true);
                console.error(err);
            })
            .then(() => setLoggingIn(false));
    }

    return <form onSubmit={(e) => {
        e.preventDefault();
        login()
    }}>
        {alertText && <div className={'alert ' + (alertIsError ? 'alert-danger' : 'alert-primary')}>{alertText}</div>}
        <input className='form-control form-control-lg m-1 '
            placeholder='Email'
            autoComplete='username'
            type='email'
            value={email}
            onChange={e => setEmail(e.currentTarget.value)} />
        <input className='form-control form-control-lg m-1'
            placeholder='Password'
            autoComplete='current-password'
            type='password'
            value={password}
            onChange={e => setPassword(e.currentTarget.value)} />
        <div className='d-flex justify-content-end mt-3'>
            <button className='btn btn-primary btn-lg' disabled={loggingIn || !submittable} onClick={login}>Login</button>
        </div>
    </form>
}


export const RegisterRoute: FunctionComponent<{ onRegistered: () => void }> = ({ onRegistered }) => {
    return <div className='row'>
        <div className='offset-md-3 col-md-6 mt-3'>
            <Register onRegistered={onRegistered} />
        </div>
    </div>
}
const Register: FunctionComponent<{ onRegistered: () => void }> = ({ onRegistered }) => {
    const [submittable, setSubmittable] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [registering, setRegistering] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setSubmittable(checkSubmittable());
    }, [email, password, firstName, lastName, passwordConfirm])

    const checkSubmittable = () => {
        console.log(email, password, firstName, lastName, passwordConfirm === password)
        if (email && password && firstName && lastName && passwordConfirm && password === passwordConfirm) {
            return true;
        } else {
            return false;
        }
    }
    const register = () => {
        if (!checkSubmittable())
            return;
        setRegistering(true)
        setError('');

        api.register(email, password, firstName, lastName)
            .then(() => onRegistered())
            .catch(err => {
                setError(err);
                console.error(err);
            })
            .then(() => setRegistering(false));
    }
    return <form onSubmit={register}>
        {error && <div className='alert alert-danger'>{error}</div>}
        <div className='row'>
            <div className='col-md p-1'>
                <input className='form-control form-control-lg '
                    placeholder='Email'
                    autoComplete='username'
                    value={email}
                    type='email'
                    onChange={e => setEmail(e.currentTarget.value)} />

            </div>
        </div>

        <div className='row'>
            <div className='col-md p-1'>
                <input
                    className='form-control form-control-lg'
                    autoComplete='given-name'
                    placeholder='First name'
                    value={firstName}
                    
                    onChange={e => setFirstName(e.currentTarget.value)} />
            </div>
            <div className='col-md p-1'>
                <input 
                className='form-control form-control-lg' 
                autoComplete='family-name'
                placeholder='Last name' 
                value={lastName} 
                onChange={e => setLastName(e.currentTarget.value)} />
            </div>
        </div>
        <div className='row'>
            <div className='col-md p-1'>
                <input className='form-control form-control-lg'
                    value={password}
                    autoComplete='new-password'
                    placeholder='Password'
                    type='password'
                    onChange={e => setPassword(e.currentTarget.value)} />
            </div>
        </div>
        <div className='row'>
            <div className='col-md p-1'>
                <input className='form-control form-control-lg'
                    value={passwordConfirm}
                    autoComplete='new-password'
                    placeholder='Confirm password'
                    type='password'
                    onChange={e => setPasswordConfirm(e.currentTarget.value)} />
            </div>
        </div>
        <div className='d-flex justify-content-end mt-3'>
            <button className='btn btn-primary btn-lg' disabled={registering || !submittable} onClick={register}>Register</button>
        </div>
    </form>
}