import React, { FunctionComponent, RefObject, useEffect, useRef, useState } from 'react';
import api from './api';
import {
    BalanceItem, NewBalanceItem, BalanceItemProperties, DueProto, FixedCost,
    Frequency, GeneratedItem, Policy, PolicyDueFixed, PolicyDueVariable, PolicyMinusId,
    Type, VariableCost, PublicEntity, FixedDue,
    CostType,
    LateCostPolicy,
    PolicyCostVariable,
    PolicyCostFixed,
} from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretSquareLeft, faTrash } from '@fortawesome/free-solid-svg-icons'

const frequencyName = (freq: Frequency, interval: number) => {
    if (freq === Frequency.Daily) {
        if (interval === 1) {
            return 'Day'
        }
        return 'Days';
    }
    if (freq === Frequency.Weekly) {
        if (interval === 1)
            return 'Week'
        return 'Weeks'
    }
    if (freq === Frequency.Monthly) {
        if (interval === 1)
            return 'Month';
        return 'Months';
    }
    if (freq === Frequency.Yearly) {
        if (interval === 1)
            return 'Year';
        return 'Years';
    }
}

const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void) => {
    useEffect(() => {
        const handleEventOutside = (event: MouseEvent | TouchEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };
        document.addEventListener('mousedown', handleEventOutside);
        document.addEventListener('touchstart', handleEventOutside);

        return () => {
            document.removeEventListener('mousedown', handleEventOutside);
            document.removeEventListener('touchstart', handleEventOutside);
        };
    }, [ref, callback]);
};

export const BillsRoute: FunctionComponent<{ entity: PublicEntity, refreshEntity: () => void }> = ({ entity, refreshEntity }) => {
    const [balanceItems, setBalanceItems] = useState([] as Array<BalanceItem>);
    const [defaultIncomeDropdown, setDefaultIncomeDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const refreshBalanceItems = () => {
        if (!entity) return;

        api.getBalanceItems(entity.id)
            .then(balanceItems => {
                console.log('Balance Items', balanceItems)
                setBalanceItems(balanceItems);
            })
            .catch(err => {
                console.error(err);
            })
    }

    useClickOutside(dropdownRef, () => {
        setDefaultIncomeDropdown(false);
    });


    useEffect(() => {
        refreshBalanceItems();
    }, [entity]);

    if (!entity)
        return <div>...</div>
    return <div className='container mt-3'>
        <div className='row'>
            <div className='col-md-10 offset-md-1'>
                <div className='d-flex justify-content-between'>
                    <h2>Bills and Income</h2>
                    <div className='d-flex align-items-center'>
                        <span className='mx-2'>Default income</span>
                        <div className={'dropdown'} ref={dropdownRef}>
                            <a className='btn btn-secondary dropdown-toggle cursor-pointer' onClick={() => setDefaultIncomeDropdown(!defaultIncomeDropdown)}>
                                {balanceItems.find(item => item.id === entity.defaultIncome)?.name || 'None'}
                            </a>

                            <ul className={'dropdown-menu ' + (defaultIncomeDropdown ? 'show' : '')}>
                                {balanceItems.filter(item => item.income)
                                    .map((item, i) => <li key={i}><a className='dropdown-item cursor-pointer'
                                        onClick={() => {
                                            api.setDefaultIncome(entity.id, item.id).then(() => {
                                                refreshEntity();
                                            })
                                            setDefaultIncomeDropdown(false);
                                        }}>
                                        {item.name}
                                    </a></li>
                                    )}
                                <li><a className='dropdown-item cursor-pointer text-secondary' onClick={() => {
                                    api.clearDefaultIncome(entity.id).then(() => {
                                        refreshEntity();
                                    })
                                    setDefaultIncomeDropdown(false);
                                }}>None</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Bills entityId={entity.id} balanceItems={balanceItems} refreshBalanceItems={refreshBalanceItems} />
            </div>
        </div>
    </div>
}
const DueEditor: FunctionComponent<{
    currentDue?: PolicyDueVariable | PolicyDueFixed,
    onNextDue: (nextDue?: PolicyDueVariable | PolicyDueFixed) => void
}> = ({ currentDue, onNextDue }) => {
    const getReferenceTimeFromDate = (referenceDate?: DueProto['referenceDate']): number | undefined => {
        if (!referenceDate)
            return;
        return new Date(referenceDate.month + '/' + referenceDate.day + '/' + referenceDate.year).getTime();
    }

    const [dueType, setDueType] = useState(currentDue?.type ?? Type.Fixed);
    const [referenceTime, setReferenceTime] = useState(getReferenceTimeFromDate(currentDue?.referenceDate) as undefined | number);
    const [maxDue, setMaxDue] = useState(currentDue?.type === Type.Variable && currentDue.max || undefined as undefined | number);
    const [graceDays, setGraceDays] = useState(currentDue?.graceDays ?? 0);

    useEffect(() => {
        const referenceDate = getReferenceDate(referenceTime)
        if (dueType === Type.Fixed && referenceDate) {
            onNextDue({ type: Type.Fixed, referenceDate, graceDays });
        } else if (dueType === Type.Variable && referenceDate && maxDue) {
            onNextDue({ type: Type.Variable, referenceDate, max: maxDue, graceDays });
        } else {
            console.log(dueType === Type.Variable, referenceDate, maxDue)
            return onNextDue();
        }
    }, [dueType, referenceTime, maxDue, graceDays])

    const getReferenceDate = (date?: number): DueProto['referenceDate'] | undefined => {
        if (!date)
            return undefined;

        const nextDate = new Date(date);

        return {
            month: nextDate.getMonth() + 1,
            day: nextDate.getDate(),
            year: nextDate.getFullYear(),
        }
    }

    return <div className='row my-1 g-3 align-items-center'>
        <div className='col-md-2'>
            <div><small className='invisible'>test</small></div>
            <label htmlFor='interval' className='col-form-label'>Due date is</label>
        </div>
        <div className='col-auto'>
            <div><small className='invisible'>test</small></div>
            <div className='btn-group'>
                <input type='radio'
                    className='btn-check'
                    id='duefixed'
                    checked={dueType === Type.Fixed}
                    onChange={e => setDueType(e.currentTarget.checked ? Type.Fixed : Type.Variable)} />
                <label className='btn btn-outline-primary' htmlFor='duefixed'>Fixed</label>

                <input type='radio'
                    className='btn-check'
                    id='duevariable'
                    checked={dueType === Type.Variable}
                    onChange={e => setDueType(e.currentTarget.checked ? Type.Variable : Type.Fixed)} />
                <label className='btn btn-outline-primary' htmlFor='duevariable'>Variable</label>
            </div>
        </div>
        <div className='col-auto'>
            <div><small className=''>Date</small></div>
            <DateEditor value={referenceTime} onChange={(nextTime) => setReferenceTime(nextTime)} />
        </div>
        <div className='col-auto'>
            <div><small className={dueType === Type.Fixed ? 'invisible' : ''}>Max due days</small></div>
            {dueType === Type.Variable && <input
                type='number'
                className='form-control'
                placeholder='Days after'
                value={maxDue ?? 1}
                onChange={e => setMaxDue(Math.max(1, parseInt(e.currentTarget.value)))} />}
        </div>

        <div className='col-auto'>
            <div><small className=''>Late after days (optional)</small></div>
            {<input
                type='number'
                className='form-control'
                placeholder='Grace days'
                value={graceDays}
                onChange={e => setGraceDays(Math.max(0, parseInt(e.currentTarget.value)))} />}
        </div>
    </div>
}
const CostEditor: FunctionComponent<{
    currentCost?: PolicyCostFixed | PolicyCostVariable,
    onNextCost: (cost?: PolicyCostFixed | PolicyCostVariable) => void
}> = ({ currentCost, onNextCost }) => {
    const getMin = () => {
        if (currentCost && currentCost.type === Type.Fixed) {
            return currentCost.cost;
        } else if (currentCost && currentCost.type === Type.Variable)
            return currentCost.range[0];
        return undefined
    }
    const getMax = () => {
        if (currentCost && currentCost.type === Type.Fixed) {
            return undefined;
        } else if (currentCost && currentCost.type === Type.Variable)
            return currentCost.range[1];
        return undefined
    }


    const [costType, setCostType] = useState(currentCost?.type ?? Type.Fixed);


    const [min, setMin] = useState(getMin());
    const [max, setMax] = useState(getMax());

    const lateEditor = useRef(null as null | HTMLDivElement);
    const [lateType, setLateType] = useState(currentCost?.late?.type ?? CostType.Value);
    const [showLateTypeDropdown, setShowLateTypeDropdown] = useState(false);

    const [late, setLate] = useState(!currentCost?.late?.value ? undefined : {
        type: lateType,
        value: currentCost?.late?.value ?? 0
    } as LateCostPolicy | undefined)

    useClickOutside(lateEditor, () => {
        setShowLateTypeDropdown(false);
    })
    useEffect(() => {
        if (costType === Type.Fixed && min) {
            onNextCost({
                type: Type.Fixed,
                cost: min,
                late
            })
        } else if (costType === Type.Variable && min && max) {
            onNextCost({
                type: Type.Variable,
                range: [min, max],
                late
            })
        } else {
            onNextCost(undefined);
        }
    }, [costType, min, max, late])

    const onLateFee = (value: number) => {
        if (isNaN(value)) setLate(undefined);
        const nextValue = Math.max(0, value);

        setLate({ type: lateType, value: nextValue })
    }
    return <div className='row my-1 g-3 align-items-center'>
        <div className='col-md-2'>
            <div className='invisible'><small>test</small></div>
            <label htmlFor='interval' className='col-form-label'>Cost is</label>
        </div>
        <div className='col-auto'>
            <div className='invisible'><small>test</small></div>
            <div className='btn-group'>
                <input type='radio'
                    className='btn-check'
                    id='costfixed'
                    checked={costType === Type.Fixed}
                    onChange={e => setCostType(e.currentTarget.checked ? Type.Fixed : Type.Variable)} />
                <label className='btn btn-outline-primary' htmlFor='costfixed'>Fixed</label>

                <input type='radio'
                    className='btn-check'
                    id='costvariable'
                    checked={costType === Type.Variable}
                    onChange={e => setCostType(e.currentTarget.checked ? Type.Variable : Type.Fixed)} />
                <label className='btn btn-outline-primary' htmlFor='costvariable'>Variable</label>
            </div>
        </div>
        <div className='col-auto'>
            <small>Cost</small>
            <input type='number' className='form-control'
                value={(min || 0).toFixed(2)}
                onChange={e => setMin(Math.max(0, parseFloat(e.currentTarget.value)))} />
        </div>
        <div className='col-auto'>
            {costType === Type.Variable && <><small>Maximum cost</small>
                <input
                    type='number'
                    className='form-control'
                    placeholder='Max cost'
                    value={(max || 0).toFixed(2)}
                    onChange={e => setMax(Math.max(0, parseFloat(e.currentTarget.value)))}
                />
            </>}
        </div>
        <div className='col-auto'>
            <small>Late fee (optional)</small>
            {<div className='input-group' ref={lateEditor}>
                <input
                    type='number'
                    className='form-control'
                    placeholder='Late fee '
                    value={(late?.value || 0).toFixed(2)}
                    onChange={e => onLateFee(parseFloat(e.currentTarget.value))}
                />
                <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    onClick={() => setShowLateTypeDropdown(!showLateTypeDropdown)}
                    type="button">{lateType === CostType.Percentage ? '%' : '$'}</button>
                <ul className={"dropdown-menu dropdown-menu-end " + (showLateTypeDropdown ? 'show' : '')} >
                    <li><a className="dropdown-item cursor-pointer" onClick={() => {
                        setLateType(CostType.Percentage)
                        setShowLateTypeDropdown(false);
                    }}>%</a></li>
                    <li><a className="dropdown-item cursor-pointer" onClick={() => {
                        setLateType(CostType.Value)
                        setShowLateTypeDropdown(false);
                    }}>$</a></li>
                </ul>
            </div>
            }
        </div>
    </div>
}
const DateEditor: FunctionComponent<{ value?: number; onChange: (value?: number) => void, className?: string }> = ({ value, onChange, className }) => {
    const getDateFormatted = () => {
        if (!value)
            return ''
        const date = new Date(value);

        return [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(part => part < 10 ? '0' + part : part).join('-');
    }

    const getDateNumber = (nextValue?: string) => {
        if (!nextValue) return;
        const [year, month, day] = nextValue.split('-').map(part => parseInt(part));
        return new Date(year, month - 1, day).getTime();
    }
    return <input
        type='date'
        value={getDateFormatted()}
        className={'form-control ' + (className ?? '')}
        onChange={e => onChange(getDateNumber(e.currentTarget.value))}
    />
}
const PolicyEditor: FunctionComponent<{
    currentPolicy?: PolicyMinusId | Policy,
    onPolicy: (policy: PolicyMinusId) => void,
    passthrough: boolean
}> = ({ currentPolicy, onPolicy, passthrough }) => {
    const [effective, setEffective] = useState(currentPolicy?.effective as number | undefined);
    const [ends, setEnds] = useState(currentPolicy?.ends as number | undefined);
    const [frequency, setFrequency] = useState(currentPolicy?.frequency || Frequency.Monthly as Frequency);
    const [interval, setInterval] = useState(currentPolicy?.interval || 1 as number);
    const [due, setDue] = useState(currentPolicy?.due as PolicyDueFixed | PolicyDueVariable | undefined);
    const [cost, setCost] = useState(currentPolicy?.cost as PolicyCostFixed | PolicyCostVariable | undefined)
    const [showFrequencyDropdown, setShowFrequencyDropdown] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const frequencyDropdownRef = useRef(null as null | HTMLDivElement);

    useEffect(() => {

        const submittable = checkSubmittable()
        setSubmittable(submittable);

        if (submittable && passthrough)
            submit();
    }, [frequency, interval, due, cost, effective, ends])
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (frequencyDropdownRef.current && !frequencyDropdownRef.current.contains(event.target as Node)) {
                setShowFrequencyDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showFrequencyDropdown]);
    const checkSubmittable = (): boolean => {
        const isSubmittable = () => {
            if (!([Frequency.Daily, Frequency.Weekly, Frequency.Monthly, Frequency.Yearly].indexOf(frequency) !== -1))
                throw 'Frequency is not valid'
            if (!due) {
                throw 'Due is not valid' + due
            }
            if (!cost)
                throw 'Cost is not valid'
            if (!interval)
                throw 'Interval is not valid'
        }
        try {
            isSubmittable();
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
    const submit = () => {
        if (checkSubmittable())
            onPolicy({
                frequency,
                cost: cost as PolicyCostFixed | PolicyCostVariable,
                due: due as PolicyDueFixed | PolicyDueVariable,
                interval,
                effective,
                ends
            });
        else {
            console.log('policy not submittable yet')
        }
    }

    return <div className=''>
        <div className='row g-3 align-items-center'>
            <div className='col-md-2'>
                <label htmlFor='interval' className='col-form-label'>Repeats every</label>
            </div>
            <div className='col-auto'>
                <input type='number'
                    id='interval'
                    className='form-control'
                    value={interval}
                    onChange={e => setInterval(Math.max(parseInt(e.currentTarget.value), 1))} />
            </div>
            <div className='col-auto'>
                <div className={'dropdown'} ref={frequencyDropdownRef}>
                    <a className='btn btn-secondary dropdown-toggle cursor-pointer' onClick={() => setShowFrequencyDropdown(!showFrequencyDropdown)}>
                        {frequencyName(frequency, interval)}
                    </a>

                    <ul className={'dropdown-menu ' + (showFrequencyDropdown ? 'show' : '')}>
                        {[Frequency.Monthly, Frequency.Weekly, Frequency.Daily, Frequency.Yearly]
                            .map((freq, i) => <li key={i}><a className='dropdown-item cursor-pointer'
                                onClick={() => {
                                    setFrequency(freq);
                                    setShowFrequencyDropdown(false);
                                }}>
                                {frequencyName(freq, interval)}
                            </a></li>
                            )}
                    </ul>
                </div>
            </div>
        </div>

        <DueEditor currentDue={due} onNextDue={nextDue => setDue(nextDue)} />
        <CostEditor currentCost={cost} onNextCost={nextCost => setCost(nextCost)} />

        <div className='row g-3 align-items-center my-1'>
            <div className='col-md-2'>
                Start (optional)
            </div>
            <div className='col-auto'>
                <DateEditor value={effective} onChange={nextEffective => setEffective(nextEffective)} />
            </div>
        </div>
        <div className='row g-3 align-items-center my-1'>
            <div className='col-md-2'>
                End (optional)
            </div>
            <div className='col-auto'>
                <DateEditor value={ends} onChange={nextEnds => setEnds(nextEnds)} />
            </div>
        </div>

        {!passthrough &&
            <div className='d-flex justify-content-end'>
                <button className='btn btn-primary btn-sm' disabled={!submittable} onClick={() => submit()}>{currentPolicy ? 'Modify' : 'Add'}</button>
            </div>
        }
    </div>
}
// Item Editor includes "Policies" editors
const ItemEditor: FunctionComponent<{
    currentItem?: BalanceItem,
    onClose: () => void,
    onNewItem: (nextItem: NewBalanceItem) => void,
    onItemUpdate: (nextItem: BalanceItemProperties) => void,
    onNewPolicy: (nextPolicy: PolicyMinusId) => void,
    onPolicyUpdate: (policyId: number, nextPolicy: PolicyMinusId) => void,
    onPolicyRemove: (policyId: number) => void,
}> = ({ currentItem, onClose, onNewItem, onItemUpdate, onNewPolicy, onPolicyUpdate, onPolicyRemove }) => {
    const [name, setName] = useState(currentItem?.name || '');
    const [income, setIncome] = useState(currentItem?.income || false);
    const [url, setURL] = useState(currentItem?.url || '');

    const [showOnlyPolicyEditor, setShowOnlyPolicyEditor] = useState(false);
    const [currentPolicy, setCurrentPolicy] = useState(undefined as Policy | undefined)
    // use only when creating a new item
    const [includedPolicy, setIncludedPolicy] = useState(undefined as PolicyMinusId | undefined);
    const [submittable, setSubmittable] = useState(false);

    const checkSubmittable = () => {
        if (!currentItem) { // treat as a new item, collect a newItem only
            return !!(name && url && includedPolicy);
        } else { // treat as a current item, its properties have been edited 
            return name !== currentItem.name || income !== currentItem.income || url !== currentItem.url
        }
    }
    const submit = () => {
        if (checkSubmittable()) {
            if (includedPolicy) {
                onNewItem({ name, income, url, policies: [includedPolicy] })
            } else if (currentItem) {
                onItemUpdate({ id: currentItem.id, name, income, url })
            } else {
                console.error('Undefined condition')
            }
        }
    }
    useEffect(() => {
        setSubmittable(checkSubmittable())
    }, [name, income, url, includedPolicy]);
    const onBack = () => {
        if (showOnlyPolicyEditor) {
            setShowOnlyPolicyEditor(false);
        } else {
            onClose()
        }
    }

    const editorTitle = () => {
        if (showOnlyPolicyEditor) {
            if (currentPolicy) {
                return <span className='fs-3'>Edit {name} Policy</span>
            } else {
                return <span className='fs-3'>New Policy</span>
            }
        } else {
            if (currentItem) {
                return <span className='fs-3'>Edit {currentItem.name}</span>;
            } else {
                return <span className='fs-3'>New Item</span>
            }
        }
    }
    return <div className='border border-dark-subtle rounded-4 p-3 mt-1'>
        <div className='d-flex align-items-center'>
            <a className='cursor-pointer' onClick={onBack}>
                <FontAwesomeIcon icon={faCaretSquareLeft} className='me-1' size={'2x'} />
            </a>
            {editorTitle()}
        </div>
        {showOnlyPolicyEditor
            ? <>
                <PolicyEditor currentPolicy={currentPolicy} onPolicy={nextPolicy => {
                    if (currentPolicy) {
                        onPolicyUpdate(currentPolicy.id, nextPolicy)
                    } else {
                        onNewPolicy(nextPolicy);
                    }
                }} passthrough={false} />
            </>
            : <>
                <div className='row'>
                    <div className='col py-1'>
                        <div className='btn-group'>
                            <input type='radio' className='btn-check' id='income' checked={income} onChange={e => setIncome(e.currentTarget.checked)} />
                            <label className='btn btn-outline-primary' htmlFor='income'>Income</label>

                            <input type='radio' className='btn-check' id='expense' checked={!income} onChange={e => setIncome(!e.currentTarget.checked)} />
                            <label className='btn btn-outline-primary' htmlFor='expense'>Bill/Expense</label>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col py-1'>
                        <input type='text' className='form-control' placeholder='Name/Company' value={name} onChange={e => setName(e.currentTarget.value)} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col py-1'>
                        <input type='text' placeholder='URL' value={url} onChange={e => setURL(e.currentTarget.value)} className='form-control' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col py-1'>
                        {!currentItem && <PolicyEditor currentPolicy={includedPolicy} onPolicy={nextPolicy => {
                            setIncludedPolicy(nextPolicy);

                        }} passthrough={true} />}
                        {currentItem && <div>
                            <h5>Policies</h5>
                            <div className='list-group'>
                                {currentItem.policies.map(policy => <div key={policy.id} className='list-group-item cursor-pointer' onClick={() => {
                                    setShowOnlyPolicyEditor(true);
                                    setCurrentPolicy(policy);
                                }}>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <div className='text-center'>
                                                <strong className='text-secondary'>Duration</strong>
                                            </div>
                                            <div>
                                                <strong>Effective since </strong>{policy.effective ? new Date(policy.effective).toLocaleDateString() : 'the beginning of time'}
                                            </div>
                                            <div>
                                                <strong>Ends at </strong>{policy.ends ? new Date(policy.ends).toLocaleDateString() : 'The end of time'}
                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='text-center'>
                                                <strong className='text-secondary'>Frequency</strong>
                                            </div>
                                            <div>
                                                <strong>Repeats every </strong>
                                                <span>{policy.interval} {frequencyName(policy.frequency, policy.interval)}</span>
                                            </div>
                                            <div>
                                                <strong>Based on </strong>
                                                <span>{policy.due.referenceDate.month
                                                    + '/' + policy.due.referenceDate.day
                                                    + '/' + policy.due.referenceDate.year}</span>
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>

                                            <div className='text-center'>
                                                <strong>Cost</strong>
                                            </div>
                                            <strong>{policy.cost.type === Type.Fixed ? 'Exactly' : 'Between'} </strong>
                                            <span>
                                                {policy.cost.type === Type.Fixed
                                                    ? '$' + policy.cost.cost
                                                    : '$' + policy.cost.range[0] + ' and $' + policy.cost.range[1]}
                                            </span>
                                        </div>
                                        <div className='col-sm-2 d-flex justify-content-end align-items-center'>
                                            <button className='btn btn-sm btn-danger' onClick={(e) => {
                                                e.stopPropagation();
                                                onPolicyRemove(policy.id)
                                            }}><FontAwesomeIcon icon={faTrash} /></button>
                                        </div>
                                    </div>


                                </div>)}
                                <div className='list-group-item cursor-pointer' onClick={() => {
                                    setShowOnlyPolicyEditor(true);
                                    setCurrentPolicy(undefined);
                                }}>
                                    Add updated policy
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div>
                        <h5>E2EE notes</h5>
                        
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-primary btn-lg' disabled={!submittable} onClick={() => submit()}>Submit</button>
                </div>
            </>}



    </div>
}

const ItemList: FunctionComponent<{
    items: Array<BalanceItem>,
    onSelect: (item?: BalanceItem) => void,
    onRemove: (balanceId: number) => void
}> = ({ items, onSelect, onRemove }) => {
    const getFaviconUrl = (url: string): string => {
        const domain = new URL(url).hostname;
        return `https://www.google.com/s2/favicons?domain=${domain}`;
    }

    return <div className='list-group'>
        {items.map(item => <div key={item.id} className='list-group-item cursor-pointer' onClick={() => onSelect(item)}>
            <div className='d-flex justify-content-between align-items-center'>
                <strong>{item.url && <a href={item.url} target='_blank'>
                    <img src={getFaviconUrl(item.url)} className='me-1' />
                </a>}{item.name}</strong>
                <div className='options'>
                    <button className='btn btn-danger btn-sm' onClick={(e) => {
                        e.stopPropagation();
                        onRemove(item.id)
                    }}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </div>


        </div>)}
        <div className='list-group-item cursor-pointer' onClick={() => onSelect()}>
            Add new item
        </div>
    </div>
}

const Bills: FunctionComponent<{
    entityId: string,
    balanceItems: Array<BalanceItem>,
    refreshBalanceItems: () => void
}> = ({ entityId, balanceItems, refreshBalanceItems }) => {
    const [loading, setLoading] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [currentItem, setCurrentItem] = useState(undefined as BalanceItem | undefined);

    return <div>
        {showEditor && <ItemEditor
            currentItem={currentItem}
            onClose={() => {
                setShowEditor(false);
                setCurrentItem(undefined);
            }}
            onNewItem={nextItem => {
                if (!currentItem) {
                    api.addBalanceItem(entityId, nextItem)
                        .then(() => {
                            refreshBalanceItems()
                            setShowEditor(false);
                            setCurrentItem(undefined);
                        })
                }
            }}
            onItemUpdate={nextItem => {
                api.updateBalanceItem(entityId, nextItem).then(() => {
                    refreshBalanceItems()
                    setShowEditor(false);
                    setCurrentItem(undefined);
                })
            }}
            onNewPolicy={nextPolicy => {
                if (!currentItem) {
                    console.error('Unexpected condition in onNewPolicy call');
                } else {
                    api.addPolicy(entityId, currentItem.id, nextPolicy).then(() => {
                        refreshBalanceItems()
                        setShowEditor(false);
                        setCurrentItem(undefined);
                    })
                }
            }}
            onPolicyRemove={(policyId: number) => {
                if (!currentItem)
                    return console.error('Unexpected condition in onUpdatePolicy call');
                api.removePolicy(entityId, currentItem.id, policyId)
                    .then(() => {
                        refreshBalanceItems()
                        setShowEditor(false);
                        setCurrentItem(undefined);
                    })
            }}
            onPolicyUpdate={(policyId, nextPolicy) => {
                if (!currentItem)
                    return console.error('Unexpected condition in onUpdatePolicy call');
                api.updatePolicy(entityId, currentItem.id, policyId, nextPolicy).then(() => {
                    refreshBalanceItems()
                    setShowEditor(false);
                    setCurrentItem(undefined);
                })
                    .catch(err => {
                        console.error('An error occurred', err)
                    })
            }} />}
        {!showEditor && <>
            <ItemList items={balanceItems} onSelect={(nextItem) => {
                setShowEditor(true);
                setCurrentItem(nextItem)
            }}
                onRemove={id => {
                    api.removeBalanceItem(entityId, id)
                        .then(() => {
                            refreshBalanceItems()
                        })
                        .catch(err => {

                        })
                }} />
        </>}
    </div>
}