import React, { useEffect, FunctionComponent } from 'react';

export const Modal: FunctionComponent<{
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    submitText?: string,
    title: string;
    children: React.ReactNode;
}> = ({ isOpen, onClose, onSubmit, submitText = 'Submit', title, children }) => {
    useEffect(() => {
        // Add the backdrop class to the body element when the modal is open
        if (isOpen) {
            document.body.classList.add('modal-backdrop', 'show');
        }

        // Remove the backdrop class from the body element when the modal is closed
        return () => {
            document.body.classList.remove('modal-backdrop', 'show');
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">{children}</div>
                    <div className="modal-footer d-flex justify-content-right">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={onSubmit}>
                            {submitText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export const Modal2: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    submitText?: string,
    title: string;
    children: React.ReactNode;
}> = ({ isOpen, onClose, onSubmit, submitText = 'Submit', title, children }) => {
    useEffect(() => {
        // Add a class to prevent scrolling when the modal is open
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div
            className="modal"
            tabIndex={-1}
            style={{ display: 'block', zIndex: 10000 }}
        >
            <div
                className="modal-backdrop show"

                onClick={onClose}
            />
            <div className="modal-dialog modal-dialog-centered" style={{ zIndex: 9999 }} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">{children}</div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={onSubmit}>
                            {submitText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Modal;