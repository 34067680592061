
export interface User {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    emailVerified: boolean;
    emailVerify?: {
        token: string;
        expires: number;
    }
    passwordReset?: {
        token: string;
        expires: number;
    }
    created: number;
    updated: number;
    lastLogin: number;
    status: 'active' | 'suspended';

    entities: Array<PublicEntity>;
    lastEntity: string;
}

export type LoggedInUser = Omit<User, 'password' | 'emailVerify' | 'passwordReset'>

export interface Entity<T = Date> { // we call each profile an Entity bacause it could refer to a business or person, or something else
    userId: string;
    name: string;
    defaultIncome: number; //BalanceItem id
    items: Array<BalanceItem>;
    overrides: Array<Partial<GeneratedItem<T>>>;
    lastUpdated: number,
}
export interface Rule<T = Date> {

}
export type PublicEntity = Omit<Entity, 'userId' | 'items' | 'overrides'> & {
    id: string
};

export interface GeneratedEntity extends Omit<Entity<number>, 'items' | 'overrides'> {
    defaultPolicies: Array<{ id: number, duration: number }>;
    from: number,
    to: number,
    generated: Array<GeneratedItem<number>>
}

export enum Type {
    Variable,
    Fixed
}
export enum CostType {
    Value,
    Percentage
}

export enum Frequency {
    Once,
    Daily,
    Weekly,
    Monthly,
    Yearly
}

export type NewBalanceItem = Omit<BalanceItem<PolicyMinusId>, 'id'>
export type BalanceItemProperties = Omit<BalanceItem, 'policies'>
export interface BalanceItem<T = Policy> {
    id: number;
    name: string;
    income: boolean;
    url: string;
    policies: [T, ...Array<T>]
}
export type PolicyMinusId = Omit<Policy, 'id'>;
export interface Policy {

    id: number;
    /** Defaults to -Infinity */
    effective?: number;
    /** Defaults to Infinity */
    ends?: number;
    /** Days after confirmed due date where late fee will apply */
    due: PolicyDueFixed | PolicyDueVariable;
    cost: PolicyCostFixed | PolicyCostVariable;
    /** Every x days/weeks/months/years */
    frequency: Frequency;
    interval: number;
}
export interface DueProto {
    graceDays: number;
    type: Type;
    referenceDate: {
        month: number;
        day: number;
        year: number;
    };

}
export interface PolicyDueFixed extends DueProto {
    type: Type.Fixed
}
export interface PolicyDueVariable extends DueProto {
    type: Type.Variable
    //** If the due date varies, then how many days does it take for the due date to be determined from the reference date */
    max: number;
}
export interface LateCostPolicy {
    type: CostType,
    value: number
}

interface PolicyCostProto {
    type: Type
    late?: LateCostPolicy
}

export interface PolicyCostFixed extends PolicyCostProto {
    type: Type.Fixed;
    cost: number;
}
export interface PolicyCostVariable extends PolicyCostProto {
    type: Type.Variable;
    range: [number, number];
}

export enum LateType {
    NotLate,
    PossiblyLate, // If today's date is after the minium due date/grace period range
    DefinitelyLate
}
/** Prototype due type:
 * companyProvidedDueDate: {
    type: Type
    range?: [Date, Date],
    date?: Date
}

withGraceDueDate{
    type: Type
    range?: [Date, Date],
    date?: Date
}
 * 
 */


export interface FixedDue<T = Date> {
    type: Type.Fixed;
    date: T;
    lateOn: T;
}
export interface VariableDue<T = Date> {
    type: Type.Variable;
    range: [T, T]; /** min due date, max due date */
    lateOnRange: [T, T]; /** min/max due dates with grace days added if available */
}
interface CostProto {
    type: Type,

}
export interface FixedCost extends CostProto {
    type: Type.Fixed;
    cost: number;
    lateCost?: number;
}

export interface VariableCost extends CostProto {
    type: Type.Variable;
    range: [number, number];
    lateRange?: [number, number];
}

export interface GeneratedFromPolicy<T = Date> {
    policyId: number
    id: number,
    late: LateType,
    payOn?: T, // day that the user plans to pay
    paid: boolean,
    due: FixedDue<T> | VariableDue<T>
    cost: FixedCost | VariableCost
    override: boolean,
    memo?: string;
}

export interface GeneratedItem<T = Date> extends GeneratedFromPolicy<T> {
    name: string,
    income: boolean,
    url: string
}
export type RemoveFromPolicy = Exclude<keyof GeneratedItem, 'id'>[];

export type WithStringId<T> = T & { _id: string };