import axios from 'axios'
import { BalanceItem, NewBalanceItem, BalanceItemProperties, GeneratedEntity, LoggedInUser, PolicyMinusId } from '../../types';
import { Policy } from './types';
class Api {
    me(): Promise<LoggedInUser> {
        return axios.get('/api/me/details')
            .then(res => res.data)
            .catch(err => {
                throw err;
            })
    }
    login(email: string, password: string): Promise<void> {
        return axios.post('/api/me/login', { email, password })
            .then(res => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    register(email: string, password: string, firstName: string, lastName: string) {
        return axios.post('/api/me/register', { email, password, firstName, lastName })
            .then(res => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    logout() {
        return axios.get('/api/me/logout')
            .then(res => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    generateAll(from: Date, to: Date): Promise<Array<GeneratedEntity>> {
        return axios.get('/api/me/entities/all/generate?from=' + from.getTime() + '&to=' + to.getTime())
            .then(res => res.data)
            .catch(err => {
                throw err.response.data;
            })
    }
    generateDefaultEntity(entityId: string): Promise<GeneratedEntity> {
        return axios.get('/api/me/entities/' + entityId + '/generatedefault')
            .then(res => res.data)
            .catch(err => {
                throw err.response.data;
            })
    }
    generateEntity(entityId: string, from: Date, to: Date): Promise<GeneratedEntity> {
        return axios.get('/api/me/entities/' + entityId + '/generate?from=' + from.getTime() + '&to=' + to.getTime())
            .then(res => res.data)
            .catch(err => {
                throw err.response.data;
            })
    }
    createEntity(name: string): Promise<string> {
        return axios.post('/api/me/entities/create', { name })
            .then(res => res.data)
            .catch(err => {
                throw err.response.data;
            })

    }
    setLastEntity(entityId: string) {
        return axios.post('/api/me/entities/setlastentity', { entityId })
            .then(res => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    getBalanceItems(entityId: string): Promise<Array<BalanceItem>> {
        return axios.get('/api/me/entities/' + entityId + '/balanceitems')
            .then(res => res.data)
            .catch(err => {
                throw err.response.data
            })
    }
    addBalanceItem(entityId: string, balanceItem: NewBalanceItem) {
        return axios.post('/api/me/entities/' + entityId + '/additem', {
            balanceItem
        })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    updateBalanceItem(entityId: string, balanceItem: BalanceItemProperties) {
        return axios.post('/api/me/entities/' + entityId + '/updateitem', {
            balanceItem
        })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    removeBalanceItem(entityId: string, balanceId: number) {
        return axios.post('/api/me/entities/' + entityId + '/removeitem', { balanceId })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    setDefaultIncome(entityId: string, balanceId?: number) {
        return axios.post('/api/me/entities/' + entityId + '/setdefault', { balanceId })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    clearDefaultIncome(entityId: string) {
        return axios.post('/api/me/entities/' + entityId + '/cleardefault')
            .then(() => {
            })
            .catch(err => {
                throw err.response.data;
            })
    }
    addPolicy(entityId: string, balanceId: number, nextPolicy: PolicyMinusId) {
        return axios.post('/api/me/entities/' + entityId + '/addpolicy', {
            balanceId,
            nextPolicy
        })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    updatePolicy(entityId: string, balanceId: number, policyId: number, nextPolicy: PolicyMinusId) {
        return axios.post('/api/me/entities/' + entityId + '/modifypolicy', {
            balanceId,
            policyId,
            nextPolicy
        })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    removePolicy(entityId: string, balanceId: number, policyId: number) {
        return axios.post('/api/me/entities/' + entityId + '/removepolicy', {
            balanceId,
            policyId
        })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    setPayOn(entityId: string, generatedId: number, payOn: number) {
        return axios.post('/api/me/entities/' + entityId + '/' + generatedId + '/payon', { payOn: payOn })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    setPaid(entityId: string, generatedId: number, paid: boolean) {
        return axios.post('/api/me/entities/' + entityId + '/' + generatedId + '/paid', { paid })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    setDue(entityId: string, policyId: number, generatedId: number, due: number) {
        return axios.post('/api/me/entities/' + entityId + '/' + generatedId + '/due',
            {
                due: due,
                policyId,
            })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    setCost(entityId: string, generatedId: number, cost: number) {
        return axios.post('/api/me/entities/' + entityId + '/' + generatedId + '/cost',
            {
                cost
            })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
    setMemo(entityId: string, generatedId: number, memo: string) {
        return axios.post('/api/me/entities/' + entityId + '/' + generatedId + '/memo',
            {
                memo
            })
            .then(() => {

            })
            .catch(err => {
                throw err.response.data;
            })
    }
}

const api = new Api();
export default api;